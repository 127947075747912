import React, { useState, useEffect } from "react";
import css from "../css/gamehis.module.css";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const Transactionhistory = () => {
  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;

  const [user, setUser] = useState();
  const limit = 50;
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [cardData, setCardData] = useState([]);
  const [showWithdrawalHistory, setShowWithdrawalHistory] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  useEffect(() => {
    role();
  }, [pageNumber, limit, showWithdrawalHistory]);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  const role = async () => {
    setLoading(true);
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    try {
      const res = await axios.get(`${baseUrl}me`, { headers });
      setUser(res.data);
      if (showWithdrawalHistory) {
        fetchWithdrawalHistory(res.data._id);
      } else {
        fetchDepositHistory(res.data._id);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchDepositHistory = async (id) => {
    setLoading(true);
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    try {
      const res = await axios.get(`${baseUrl}temp/deposite/${id}?page=${pageNumber}&_limit=${limit}`, { headers });
      setCardData(res.data.ress);
      setNumberOfPages(res.data.totalPages);
    } finally {
      setLoading(false);
    }
  };

  const fetchWithdrawalHistory = async (id) => {
    setLoading(true);
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    try {
      const res = await axios.get(`${baseUrl}temp/withdrawal/${id}?page=${pageNumber}&_limit=${limit}`, { headers });
      setCardData(res.data.ress);
      setNumberOfPages(res.data.totalPages);
    } finally {
      setLoading(false);
    }
  };

  const toggleHistoryType = () => {
    setShowWithdrawalHistory(!showWithdrawalHistory);
  };

  const dateFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("default", {
      month: "long",
      day: "numeric",
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });
  };

  const checkupigatewaypay = (order_id, order_token, pay_date) => {
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    axios
      .post(`${baseUrl}depositupipay/response`, { order_id, order_token }, { headers })
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title = res.data.status === "PAID" ? "Deposit submitted successfully" : "Transaction Failed";
        role();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem('token');
          window.location.reload();
        }
      });
  };

  const handlePageChange = (newPage) => {
    setPageNumber(newPage);
  };

  return (
    <div className="leftContainer" style={{ minHeight: '100vh', paddingBottom: '30px' }}>
      <ToastContainer />

    <div className="leftContainer" style={{ minHeight: "100vh", height: "100%" }}>
      <div className="pt-5 mb-3"></div>
      <div 
  className="card mt-2 p-2" 
  style={{
    backgroundColor: "#333333", 
    borderRadius: "1px", 
    padding: "10px", 
    margin: "0 auto"
  }}
>
        <div className="d-flex justify-content-between">
           <Link to="/Gamehistory" className={css.Link}>
            <span className="font-9" style={{ fontWeight: '500', color: 'black' }}>Game</span>
          </Link>
          <Link to="/transaction-history" className={css.Linked}>
            <span className="font-9" style={{ fontWeight: '500', color: 'white' }}>Wallet</span>
          </Link>
          <Link to="/Referral-history" className={css.Link}>
            <span className="font-9" style={{ fontWeight: '500', color: 'black' }}>Referral</span>
          </Link>
        </div> 
      </div>
      
{cardData && cardData.length > 0 ? (
  cardData.map((card) => {
    if (card.status === 'FAILED') return null;

    const id = card._id.toString();
    const timestamp = id.slice(0, 8);
    const counterid = parseInt(timestamp, 16);

    let titleMsg = "";
    let signIcon = null;
    let statusBgColor = '';  
    let statusTextColor = 'white';

    if (card.Req_type === 'deposit' || card.Req_type === 'bonus') {
      titleMsg = 'Cash added';
      signIcon = (
        <div className={`text-${card.status === 'Pending' ? 'danger' : 'success'}`}>
          {card.status === 'Pending' ? (
            <img src="https://i.postimg.cc/R0j1NZNQ/Minusicon.png" alt="(-)" width="12" height="12" />
          ) : (
            <img src="https://i.postimg.cc/rmFVG8B7/Plusicon.png" alt="(+)" width="12" height="12" />
          )}
        </div>
      );
      statusBgColor = card.status === 'Pending' ? '#808080' : '#388E3C';  // Dark green for paid
    } else if (card.Req_type === 'withdraw') {
      titleMsg = `Withdraw using ${card.Withdraw_type}`;
      signIcon = (
        <div className="text-danger">
          <img src="https://i.postimg.cc/R0j1NZNQ/Minusicon.png" alt="minus icon" width="12" height="12" />
        </div>
      );
      statusBgColor = card.status === 'IN PROCESS' ? '#808080' : (card.status === 'success' ? '#8BC34A' : '#ff6f61'); // Grey for IN PROCESS, light green for success
    } else if (card.Req_type === 'penalty') {
      titleMsg = 'Penalty';
      signIcon = (
        <img
          className='mx-1'
          src="https://i.postimg.cc/R0j1NZNQ/Minusicon.png"
          width='12px'
          alt=''
        /> 
      );
      statusBgColor = '#FFA500'; // Orange for penalty
    } else {
      titleMsg = 'Unknown';
      signIcon = <div className="text-success"></div>;
      statusBgColor = '#f5f5f5'; 
      statusTextColor = '#333';
    }

    return (
      <div key={card._id} className="w-96 my-1 py-2 px-3 d-flex justify-content-between align-items-center rounded"
        style={{
          backgroundColor: '#ffffff',
          borderRadius: '4px',  // Reduced border radius for sharper look
          border: '1px solid #cccccc',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
          color: '#333',
          marginBottom: '8px',  // Smaller card spacing
        }}
      >
        <div className="d-flex flex-column" style={{ alignItems: 'flex-start' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <b style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>{titleMsg}</b>
          </div>
          <h6 style={{ fontSize: '0.7rem', color: '#555' }}>OrderID: JP-{counterid}</h6>
          <div className="d-flex justify-content-between" style={{ width: '100%' }}>
            <div style={{ fontSize: '0.75rem', fontWeight: 'bold' }}>{dateFormat(card.createdAt).split(',')[0]}</div>
            <small style={{ fontSize: '0.65rem' }}>{dateFormat(card.createdAt).split(',')[1]}</small>
          </div>
{card.Req_type === 'penalty' && (
<div>
  <small style={{ fontSize: '8px', color: 'red', lineHeight: '10px', display: 'inline', fontWeight: 'normal' }}>
    Reason: Suspicious or rong activity!
  </small>
</div>
                )}
              </div>

        <div className="d-flex flex-column align-items-end">
          <div style={{
            fontSize: '0.6rem',  // Reduced font size for smaller appearance
            fontWeight: 'bold',
            marginBottom: '4px',
            padding: '4px 8px',
            backgroundColor: statusBgColor,
            borderRadius: '3px', // Smaller rounded corners
            color: statusTextColor,
            textAlign: 'center'
          }}>
            {card.status === 'Pending' && card.Req_type === 'withdraw' ? 'IN PROCESS' : card.status}
          </div>
          <div className="d-flex align-items-center">
            {signIcon}

            <span
              style={{
                fontSize: '0.85rem',
                fontWeight: 'bold',
                color:
                  card.Req_type === 'withdraw'
                    ? (card.status === 'success' ? '#8BC34A' : '#FF5733')
                    : card.Req_type === 'penalty'
                    ? 'orange'
                    : '#4caf50',
                marginLeft: '1px'
              }}
            >
            ₹<b>{card.amount} </b>
            </span>
          </div>

             {/* Check button for Pending deposit only */}
<div>
  {isButtonVisible && card.Req_type === "deposit" && card.status === "Pending" && (
    <button
      className="btn btn-sm btn-danger text-white px-1 py-1" // Reduced padding
      style={{ fontSize: '12px' }} // Smaller font size
      onClick={() => {
        checkupigatewaypay(
          card.order_id,
          card.order_token,
          dateFormat(card.createdAt)
        );
        toast.success("Status checked ✅");
        setIsButtonVisible(false); // Hide button after click
      }}
    >
      <strong>CHECK</strong>
    </button>
  )}
</div>
              </div>
            </div>
          );
        })
      ) : (
<div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
  <img
    src={process.env.PUBLIC_URL + '/Images/notransactionhistory.png'}
    alt="no data"
    width={250}
    height={250}
    className="img-fluid"
    style={{ marginTop : "20%" }}
  />
  <div className="mt-3 text-center">
    <h3 className="font-weight-bold" style={{ color: '#333' }}>No Transaction History</h3>
    <p className="text-muted">You have not made any transaction yet.</p>
  </div> 
 </div>
        )}

        {/* Pagination */}
<div role="group" className="btn-group" style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#333333', borderRadius: '5px', padding: '4px 0', width: '100%', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', color: 'white' }}>
  
  {/* Previous Button */}
  <button 
    type="button" 
    disabled={pageNumber === 0} 
    className="btn btn-primary" 
    style={{ backgroundColor: '#494949', color: 'white', border: '1px solid #fff', borderRadius: '5px', padding: '8px 12px', fontSize: '16px', cursor: 'pointer', fontWeight: 'bold', marginRight: '5px' }} 
    onClick={() => handlePageChange(pageNumber - 1)}
  >
    <span style={{ color: '#fff', fontWeight: 'bold' }}>
      <b>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor">
          <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"></path>
        </svg>
      </b>
    </span>
  </button> 
  
  {/* Page Number Buttons */}
  {[pageNumber - 1, pageNumber, pageNumber + 1].map(page => (
    page > 0 && page <= numberOfPages && (
      <button 
        key={page} 
        type="button" 
        className="btn btn-primary" 
        style={{ backgroundColor: '#494949', color: '#fff', border: '1px solid #fff', borderRadius: '5px', padding: '8px 12px', fontSize: '16px', cursor: 'pointer', fontWeight: 'bold', margin: '0 5px' }} 
        onClick={() => handlePageChange(page)}
      >
        <b><span style={{ color: '#fff', fontWeight: 'bold' }}>{page + 1}</span></b>
      </button>
    )
  ))}
  
  {/* Next Button */}
  <button 
    type="button" 
    disabled={pageNumber === numberOfPages - 1} 
    className="btn btn-primary" 
    style={{ backgroundColor: '#494949', color: 'white', border: '1px solid #fff', borderRadius: '5px', padding: '8px 12px', fontSize: '16px', cursor: 'pointer', fontWeight: 'bold', marginLeft: '5px' }} 
    onClick={() => handlePageChange(pageNumber + 1)}
  >
    <span style={{ color: '#fff', fontWeight: 'bold' }}>
      <b>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor">
          <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
        </svg>
      </b>
    </span>
  </button>
  
</div>

      </div>
    </div>
  );
};

export default Transactionhistory;